import { hasActiveToken, getToken, refreshToken } from "@fwa/src/services/auth";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { trackDataDogError } from "@fwa/src/services/dataDog";

import { type ResponseError } from "@fwa/src/types";

// eslint-disable-next-line import/no-relative-packages
import pkg from "../../../../package.json";
import { isTest } from "../utils/envUtils";

export const FWS_BASE_URL = process.env.NEXT_PUBLIC_FWS_BASE_URL || "";
export const FWA_BASE_URL = process.env.NEXT_PUBLIC_FWA_BASE_URL || "";

// this isn't a security risk because its the root package json not this packages so doesn't real dependency versions
const APPLICATION_VERSION: string = pkg.version;

type SpecialError = {
  errorDescription: string;
  data: { errorMessages: string[] }[];
};

// used in swr returns either data or an error in a promise
export const fetcher = async <DataType>(
  requestUrl: string,
  args: RequestInit | undefined = {},
  isServer?: boolean,
): Promise<DataType | void> => {
  const isFws = requestUrl.startsWith(FWS_BASE_URL);

  const originalRequest = async () =>
    fetch(requestUrl, {
      ...args,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Override User-Agent only on back end.
        ...(!isBrowser
          ? {
              "User-Agent": `Mozilla/5.0 (compatible; FWA/${APPLICATION_VERSION}; +https://github.com/CRUKorg/fundraising-web-app)`,
            }
          : {}),
        // Add auth header when logged in and calling FWS.
        ...(isFws && hasActiveToken()
          ? { "X-Authorization": `Bearer ${getToken() || ""}` }
          : {}),
        ...args.headers,
      },
    });

  const refreshAndRetry = async () => {
    const tokenRes = await refreshToken();
    // if we can't refresh then we don't retry
    if (!tokenRes) return undefined;
    return originalRequest().then((res) => {
      // try again without refresh on fail
      if (res.ok) {
        return handleStatusGood<DataType>(res);
      }
      // throw custom errors
      return handleStatusBad(res, args);
    });
  };

  // first attempt at request
  return originalRequest()
    .then((res) => {
      if (res.ok) {
        return handleStatusGood<DataType>(res);
      }
      // we only want to refresh and retry for browser based FWS requests
      if (!isServer && isFws && res.status === 401) {
        throw new Error("401");
      } else {
        // throw custom errors
        return handleStatusBad(res, args);
      }
    })
    .catch((err: Error) => {
      // catch incase 401 and refresh and retry
      if (err.message === "401" && isFws) {
        return refreshAndRetry();
      }
      // throw other errors
      throw err;
    });
};

const handleStatusGood = async <DataType>(res: Response) => {
  // handle empty data
  if (res.status === 204) {
    return Promise.resolve();
  }
  // handle data
  if (res.ok) {
    return (
      res
        .json()
        // we are never going to know the shape of the data
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then((data) => data as DataType) // the status was ok and there is a json body
        .catch(() => res as DataType) // the status was ok but there is no json body
    );
  }
  return;
};

// Handles some specific error messages from FWS
// Figures out what ResponseError to throw, ResponseError is an error with a status code
const handleStatusBad = async (
  res: Response,
  args: RequestInit | undefined = {},
): Promise<void> => {
  const defaultError = new Error(
    res.statusText || "We'll be back soon!",
  ) as ResponseError;
  defaultError.status = 500;
  const errorName = `handleStatusBad${res.status}`;
  switch (res.status) {
    case 500:
      trackDataDogError(new Error("handleStatusBad500"), {
        status: res.status,
        method: args?.method,
        url: res.url,
        payload: args?.body ? JSON.stringify(args.body) : undefined,
      });
      throw defaultError;
    case 409:
      return res.json().then((err: SpecialError) => {
        const errorText = err.data?.[0]?.errorMessages?.[0]
          ? err.data[0].errorMessages[0]
          : err.errorDescription;
        const error409 = new Error(errorText) as ResponseError;
        error409.status = 409;
        trackDataDogError(new Error("handleStatusBad409"), {
          status: res.status,
          method: args?.method,
          customMessage: JSON.stringify(err).replace(/\\"/g, '"'),
          payload: args?.body
            ? JSON.stringify(args.body).replace(/\\"/g, '"')
            : undefined,
        });
        throw error409;
      });
    default:
      trackDataDogError(new Error(errorName), {
        status: res.status,
        method: args?.method,
        url: res.url,
      });
      defaultError.status = res.status || 500;
      throw defaultError;
  }
};

// syntactical sugar to get data and error without a try catch on every page
export const pageFetcher = async <DataType>(
  url: string | null,
): Promise<{ pageData?: DataType; error?: ResponseError }> => {
  let pageData;
  let error;
  if (!url) return { pageData, error: new Error("no url") };

  // set up mock SSR fetch data for tests
  if (isTest) {
    const { ssrMocks } = await import("@fwa/playwright/apiHandlers");
    const foundReturn = ssrMocks.find((item) => item.url === url);
    if (foundReturn) {
      pageData = foundReturn.data as DataType;
      error = foundReturn.error as ResponseError;
      return { pageData, error };
    }
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    pageData = (await fetcher(url, {}, true)) as DataType | undefined;
  } catch (err) {
    // TODO figure out how to capture these caught errors SSR
    error = err as ResponseError;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return { pageData, error };
};
